import React from "react";
import { Link } from "gatsby";
import { Carousel, Grid } from "antd";

export const HomeBanner = (props) => {
  const contentStyle = {
    width: "100%",
    "object-fit": "cover",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  return (
    <Carousel
      autoplay="true"
      autoplaySpeed={4000}
      speed={1000}
      pauseOnHover={false}>
      <div>
        <div className="tw-hidden md:tw-block">
          {props.lang === "en" && (
            <Link to="/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/D_Pay_with_points_EN_e8b7eb1489.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/D_Pay_with_points_JA_85257caf97.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/D_Pay_with_points_ZH_3a7b673eeb.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
        <div className="tw-block md:tw-hidden">
          {props.lang === "en" && (
            <Link to="/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/M_Pay_with_points_EN_b780f54422.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/M_Pay_with_points_JA_3c5c231dd7.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/M_Pay_with_points_ZH_95272b9a0a.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
      </div>
      <div>
        <div className="tw-hidden md:tw-block">
          {props.lang === "en" && (
            <Link to="/riverside-golf-club">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/1_Hero_Banner_Desktop_AYANA_REWARDS_Landing_Page_3720x892_45a520ee3f.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/riverside-golf-club">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/JA_1_Hero_Banner_Desktop_AYANA_REWARDS_Landing_Page_3720x892_c10aff7da8.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/riverside-golf-club">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/ZH_1_Hero_Banner_Desktop_AYANA_REWARDS_Landing_Page_3720x892_4833b07a0a.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
        <div className="tw-block md:tw-hidden">
          {props.lang === "en" && (
            <Link to="/riverside-golf-club">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/1_Hero_Banner_Mobile_AYANA_REWARDS_Landing_Page_750_x_840_1fabe782c7.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/riverside-golf-club">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/JA_1_Hero_Banner_Mobile_AYANA_REWARDS_Landing_Page_750_x_840_c10f93643d.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/riverside-golf-club">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/ZH_1_Hero_Banner_Mobile_AYANA_REWARDS_Landing_Page_750_x_840_4e7bf11dab.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
      </div>
    </Carousel>
  );
};
